<template>
    <div class="admin-boxes-element">
        <div class="element-section section-name">
            <div class="section-title">NAME</div>
            <div class="section-content">
                {{ box.name }}
            </div>
        </div>
        <div class="element-section section-amount">
            <div class="section-title">AMOUNT</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coin.svg" alt="icon" />
                <div class="content-value">
                    <span>{{ adminFormatValue(box.amount).split('.')[0] }}</span
                    >.{{ adminFormatValue(box.amount).split('.')[1] }}
                </div>
            </div>
        </div>
        <div class="element-section section-state" v-bind:class="['state-' + box.state]">
            <div class="section-title">STATE</div>
            <div class="section-content">
                {{ box.state }}
            </div>
        </div>
        <div class="element-section section-option">
            <div class="section-title">OPTION</div>
            <div class="section-content">
                <button
                    v-on:click="adminViewButton()"
                    v-bind:disabled="socketSendLoading === 'AdminBoxRemove'"
                    class="hoverable"
                >
                    <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 576 512"
                        height="20"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"
                        ></path>
                    </svg>
                </button>
                <button
                    v-on:click="adminRemoveButton()"
                    v-bind:disabled="socketSendLoading === 'AdminBoxRemove'"
                    class="hoverable"
                >
                    <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 448 512"
                        height="18"
                        width="18"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"
                        ></path>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'AdminBoxesElement',
        props: ['box', 'onView'],
        methods: {
            ...mapActions(['adminSendBoxRemoveSocket']),
            adminFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            adminRemoveButton() {
                const data = { boxId: this.box._id };
                this.adminSendBoxRemoveSocket(data);
            },
            adminViewButton() {
                if (this.onView) this.onView(this.box);
            }
        },
        computed: {
            ...mapGetters(['socketSendLoading'])
        }
    };
</script>

<style scoped>
    .admin-boxes-element {
        width: 100%;
        height: 47px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        border-radius: 5px;
        background: rgba(19, 66, 88, 0.25);
    }

    .admin-boxes-element:nth-child(even) {
        background: rgba(19, 66, 88, 0.1);
    }

    .admin-boxes-element .element-section {
        display: flex;
        flex-direction: column;
    }

    .admin-boxes-element .element-section.section-name {
        width: 35%;
    }

    .admin-boxes-element .element-section.section-amount,
    .admin-boxes-element .element-section.section-state {
        width: 25%;
    }

    .admin-boxes-element .element-section.section-option {
        width: 15%;
    }

    .admin-boxes-element .section-title {
        display: none;
        font-size: 13px;
        font-weight: 600;
        color: #8bacc8;
    }

    .admin-boxes-element .section-content {
        display: flex;
        align-items: center;
    }

    .admin-boxes-element .element-section.section-name .section-content {
        font-size: 14px;
        font-weight: 400;
        color: #bbbfd0;
    }

    .admin-boxes-element .element-section.section-amount .section-content img {
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }

    .admin-boxes-element .element-section.section-amount .content-value {
        font-size: 10px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .admin-boxes-element .element-section.section-amount .content-value span {
        font-size: 14px;
        font-weight: 800;
        color: #ffffff;
    }

    .admin-boxes-element .element-section.section-state .section-content {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 400;
        color: #8bacc8;
    }

    .admin-boxes-element .element-section.section-state.state-active .section-content {
        color: #00ffc2;
    }

    .admin-boxes-element .element-section.section-state.state-paused .section-content {
        color: #d15e5e;
    }

    .admin-boxes-element .element-section.section-option .section-content {
        justify-content: flex-end;
        gap: 10px;
    }

    .admin-boxes-element .element-section.section-option .section-content button {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: #bbbfd0;
        transition: color 0.3s ease;
    }

    .admin-boxes-element .element-section.section-option .section-content button:hover {
        color: #ffffff;
    }

    .admin-boxes-element .element-section.section-option .section-content button svg {
        margin-right: 8px;
        fill: #bbbfd0;
        transition: fill 0.3s ease;
    }

    .admin-boxes-element .element-section.section-option .section-content button:hover svg {
        fill: #ffffff;
    }

    @media only screen and (max-width: 1250px) {
        .admin-boxes-element {
            height: auto;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px 20px;
        }

        .admin-boxes-element .element-section {
            width: 100% !important;
            margin-top: 10px;
        }

        .admin-boxes-element .element-section.section-user {
            margin-top: 0;
        }

        .admin-boxes-element .element-section.section-option {
            align-items: flex-start;
        }

        .admin-boxes-element .section-title {
            display: block;
        }

        .admin-boxes-element .section-content {
            margin-top: 5px;
        }
    }
</style>
