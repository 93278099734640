var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"admin-stats-element"},[_c('div',{staticClass:"element-section section-date"},[_c('div',{staticClass:"section-title"},[_vm._v("DATE")]),_c('div',{staticClass:"section-content"},[_vm._v(" "+_vm._s(new Date(_vm.stat.createdAt).toLocaleDateString('en-US'))+" ")])]),_c('div',{staticClass:"element-section section-npc"},[_c('div',{staticClass:"section-title"},[_vm._v("NPC")]),_c('div',{staticClass:"section-content"},[_vm._v(" "+_vm._s(_vm.stat.stats.total.user)+" ")])]),_c('div',{staticClass:"element-section section-limiteds"},[_c('div',{staticClass:"section-title"},[_vm._v("LIMITEDS")]),_c('div',{staticClass:"section-content"},[_c('img',{attrs:{"src":require("@/assets/img/icons/coin.svg"),"alt":"icon"}}),_c('div',{staticClass:"content-value"},[_c('span',[_vm._v(_vm._s(_vm.adminFormatValue(_vm.stat.stats.limited.deposit).split('.')[0]))]),_vm._v("."+_vm._s(_vm.adminFormatValue( _vm.stat.stats.limited.deposit - _vm.stat.stats.limited.withdraw ).split('.')[1])+" ")])])]),_c('div',{staticClass:"element-section section-robux"},[_c('div',{staticClass:"section-title"},[_vm._v("ROBUX")]),_c('div',{staticClass:"section-content"},[_c('img',{attrs:{"src":require("@/assets/img/icons/coin.svg"),"alt":"icon"}}),_c('div',{staticClass:"content-value"},[_c('span',[_vm._v(_vm._s(_vm.adminFormatValue(_vm.stat.stats.robux.deposit).split('.')[0]))]),_vm._v("."+_vm._s(_vm.adminFormatValue( _vm.stat.stats.robux.deposit - _vm.stat.stats.robux.withdraw ).split('.')[1])+" ")])])]),_c('div',{staticClass:"element-section section-steam",class:{
            'section-positive': _vm.stat.stats.steam.deposit - _vm.stat.stats.steam.withdraw >= 0
        }},[_c('div',{staticClass:"section-title"},[_vm._v("STEAM")]),_c('div',{staticClass:"section-content"},[_vm._v(" $"+_vm._s(_vm.adminFormatValue(_vm.stat.stats.steam.deposit - _vm.stat.stats.steam.withdraw))+" ")])]),_c('div',{staticClass:"element-section section-gift",class:{
            'section-positive': _vm.stat.stats.gift.deposit - _vm.stat.stats.gift.withdraw >= 0
        }},[_c('div',{staticClass:"section-title"},[_vm._v("GIFT CARDS")]),_c('div',{staticClass:"section-content"},[_vm._v(" $"+_vm._s(_vm.adminFormatValue(_vm.stat.stats.gift.deposit - _vm.stat.stats.gift.withdraw))+" ")])]),_c('div',{staticClass:"element-section section-crypto",class:{
            'section-positive': _vm.stat.stats.crypto.deposit - _vm.stat.stats.crypto.withdraw >= 0
        }},[_c('div',{staticClass:"section-title"},[_vm._v("CRYPTO")]),_c('div',{staticClass:"section-content"},[_vm._v(" $"+_vm._s(_vm.adminFormatValue(_vm.stat.stats.crypto.deposit - _vm.stat.stats.crypto.withdraw))+" ")])]),_c('div',{staticClass:"element-section section-cc",class:{
            'section-positive': _vm.stat.stats.credit.deposit - _vm.stat.stats.credit.withdraw >= 0
        }},[_c('div',{staticClass:"section-title"},[_vm._v("CC")]),_c('div',{staticClass:"section-content"},[_vm._v(" $"+_vm._s(_vm.adminFormatValue(_vm.stat.stats.credit.deposit - _vm.stat.stats.credit.withdraw))+" ")])]),_c('div',{staticClass:"element-section section-profit",class:{
            'section-positive': _vm.stat.stats.total.deposit - _vm.stat.stats.total.withdraw >= 0
        }},[_c('div',{staticClass:"section-title"},[_vm._v("NET PROFIT")]),_c('div',{staticClass:"section-content"},[_vm._v(" $"+_vm._s(_vm.adminFormatValue(_vm.stat.stats.total.deposit - _vm.stat.stats.total.withdraw))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }